import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';

import swal from 'sweetalert';


class PlayPage extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false,
    status:false,
    url:"",
    urliframe:"/gameslotmobile.html",
    ListItem:[],
    indexgame:4,
    setonclick:false,
    time:3000
  };

  componentDidMount(){

    this.datatime()

  }


  async datatime(){
    try {
      setInterval(async () => {
        const res = await fetch(`${config.url}/content/GetDataLoad`);
        const data = await res.json();


        this.setState({
          url: data.url,
          status: data.status,
        })
      }, this.state.time);
    } catch(e) {
      console.log(e);
    }
  }



CallEnterTokenKey = () =>{
  this.props.history.push("/GamePage");
}


inputRegister = (e) =>{

  this.setState({ [e.target.name]: e.target.value });

}


iframeGame = () =>{
  this.setState({urliframe:this.state.url})
  this.setState({setonclick:true,time:0,status:false})
}


gohome = () =>{
  this.setState({urliframe:"/gameslotmobile.html"})
  this.setState({setonclick:false,time:3000,status:false})
}



  render() {

  if(this.state.status == true && this.state.setonclick == false){
    return (
      <>
      <Container>
          <div className="app-conten">

            <div className='full-click' onClick={this.iframeGame}></div>

          <iframe src={this.state.urliframe} title="ROOTSLOT"></iframe>
          </div>
      </Container>
      </>
  );
  }
  else{
    return (
      <>
      <Container>
          <div className="app-conten">
          {/* {
            this.state.status == true ? <div className='full-click' onClick={this.iframeGame}></div>:""
          } */}
          {
            this.state.setonclick == true ? <img onClick={this.gohome} className='homeicon' src="https://app.kub168.com/landing/assets/img/backmenu02.png"/>:""
          }
          <iframe src={this.state.urliframe} title="ROOTSLOT"></iframe>
          </div>
      </Container>
      </>
  );
  }


  }
}

export default (PlayPage);