import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import loading from '../../asset/images/neeyai/loading.gif';

import vdo from '../../asset/vdo/ShikiOriori.mp4';
import coming from '../../asset/images/coming.png';


class ComingPage extends React.Component {
  state = {
    loading:"",
  };

  componentDidMount(){
  }




  render() {
    return (
        <>
        <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video>
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={8}>
                        <img src={coming}/>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (ComingPage);