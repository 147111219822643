import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import logo from '../../asset/images/rootslot.png';

import swal from 'sweetalert';


class RegisterPage extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false
  };

  
  componentDidMount(){


    var url = new URL(window.location.href);
    var root = url.searchParams.get("root");
    var pin = url.searchParams.get("pin");

    if(root != null){
      if(root == "unlock"){
        sessionStorage.setItem("rootweb","www.kub168.com")
      }
      else if(root == "unl๐ck"){
        sessionStorage.setItem("rootweb","www.###.com")
      }
      else{
        sessionStorage.setItem("rootweb","www.kub168.com")
      }
    }
    else{
      sessionStorage.setItem("rootweb","www.kub168.com")
    }

    this.setState({tokenkey:pin})

  }



CallRegister = () =>{
  if(this.state.register == true){
    this.setState({
      loading:loading,
      lookinput:true,
      bankList:''
    })
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/member");
    }, 2000);
  }
}



CallEnterTokenKey = () =>{

  if(this.state.tokenkey == undefined){
    swal("เกิดข้อผิดพลาด", "กรุณาใส่รหัสเปิดใช้งาน 6 หลัก",{ dangerMode: true});
    return
  }

  swal("กำลังเปิดใช้งาน", {
    title: "ประมวลผล",
    buttons: false,
    timer: 5000,
  });

  axios({
    method: 'get',
    url: (`${config.url}/content/PostRootSlotEnterKey?key=${this.state.tokenkey}`),
  })
  .then(resp => {
    if(resp.data.status){

      swal("เปิดใช้งานสำเร็จ", {
        icon: "success",
        title: "สำเร็จ",
        buttons: false,
        timer: 2000,
      });

      setTimeout(() => {
        this.setState({loading:true});
        // this.props.history.push("/GamePage");
        window.location.assign("https://app.kub168.com/")
      }, 2000);
    }
    else{
      swal("เกิดข้อผิดพลาด", "รหัสนี้ได้ทำการเปิดใช้งานไปแล้ว หรือ ไม่สามารถใช้งานได้",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}


inputRegister = (e) =>{

  this.setState({ [e.target.name]: e.target.value });

}



  render() {
    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={10}>
                        <Row>
                          <div className="image-profile">
                            <img src={logo}/>
                          </div>
                            <Col sm={7}>
                              <Form className="fix-text-left" noValidate>
                              <Form.Group className="mb-3" controlId="formGridAddress1">
                                  {/* <Form.Label>ใส่รหัสเปิดใช้งาน 6 หลัก</Form.Label> */}
                                  <Form.Control value={this.state.tokenkey} placeholder="Enter Token Key" name="tokenkey" onChange={this.inputRegister} disabled={this.state.lookinput} required={true}/>
                                  <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
                                </Form.Group>
                                <Row>
                                    <Col sm={12}><h5>นโยบายคุ้มครองข้อมูลส่วนบุคคล <a href="#">ข้อกำหนดและเงื่อนไงการให้บริการ</a></h5></Col>
                                </Row>
                                <Row>
                                  <Col sm={12}>
                                    <div className="app-login-group btn-login fix-center"><div className="app-center-div" onClick={this.CallEnterTokenKey}>เปิดใช้งาน <img className="fix-loading" src={this.state.loading}/></div></div>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (RegisterPage);