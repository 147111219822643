import React from 'react';
import { Container } from "./styles";
import { Row,Col,Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import logo from '../../asset/images/rootslot.png';

import swal from 'sweetalert';


class GenTokenKey extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false,
    tokenkey:""
  };

  
  componentDidMount(){


    var url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    if(token != null){
      var decoded = jwt_decode(token);
      this.setState({
        imageProfile:decoded.linePicture,
        displayname:decoded.lineName
      })
    }

    this.setState({bankList:config.ListBank.map(o=> {
        return(
          <option>{o}</option>
        )
    })}) 
  }



CallRegister = () =>{
  if(this.state.register == true){
    this.setState({
      loading:loading,
      lookinput:true,
      bankList:''
    })
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/member");
    }, 2000);
  }
}



CallEnterTokenKey = () =>{

  swal("กำลังสร้าง Token Key", {
    title: "ประมวลผล",
    buttons: false,
    timer: 3000,
  });

  axios({
    method: 'get',
    url: (`${config.url}/content/GenRootSlotEnterKey`),
  })
  .then(resp => {
    if(resp.data.status){
      this.setState({tokenkey:'https://rootslot.com/?pin='+resp.data.key})
    }
    else{
      swal("เกิดข้อผิดพลาด", "สร้างรหัสใหม่อีกครั้ง",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}


inputRegister = (e) =>{

  this.setState({ [e.target.name]: e.target.value });

}

coppyKey = () =>{
  if(this.state.tokenkey != ""){
    navigator.clipboard.writeText(this.state.tokenkey)
    swal("คัดลองสำเร็จ", {
      icon: "success",
      title: "สำเร็จ",
      buttons: false,
      timer: 2000,
    });
    this.setState({tokenkey:""})
  }
  else{
    navigator.clipboard.writeText(this.state.tokenkey)
    swal("กรุณาสร้าง Token key ก่อน", {
      icon: "warning",
      title: "เกิดข้อผิดพลาด",
      buttons: false,
      timer: 2000,
    });
  }
}

removeKey = () =>{
  this.setState({tokenkey:""})
  swal("ล้างข้อมูลเรียบร้อย", {
    icon: "success",
    title: "สำเร็จ",
    buttons: false,
    timer: 2000,
  });
}



  render() {
    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={10}>
                        <Row>
                          <div className="image-profile">
                            <img src={logo}/>
                          </div>
                            <Col sm={7}>
                              <Form className="fix-text-left" noValidate>

                              <Row>
                                  <Col sm={12}>
                                    <div className="app-login-group btn-login fix-center"><div className="app-center-div" onClick={this.CallEnterTokenKey}>(1) สร้าง ลิ้งค์ <img className="fix-loading" src={this.state.loading}/></div></div>
                                  </Col>
                                </Row>
                                <br/>
                              <Form.Group className="mb-3" controlId="formGridAddress1">
                              <center>
                                <h2 className='c-h2'>คัดลอกข้อความในช่องทั้งหมด</h2>
                              <Col sm={10}>
                                <Form.Control value={this.state.tokenkey} placeholder="Enter Token Key" name="tokenkey" onChange={this.inputRegister} disabled={true} required={true}/>
                              </Col>
                              <br/>
                              <Col sm={10}>
                                {/* <Button variant="success" onClick={this.coppyKey}>(2) กดปุ่ม เพื่อคัดลอกส่งให้ลูกค้าที่โอนเงิน</Button>{' '} */}
                                <Button variant="warning" onClick={this.removeKey}>(2) ล้างข้อมูล</Button>{' '}
                              </Col>
                              </center>
                                </Form.Group>
                              </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (GenTokenKey);