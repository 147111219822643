import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import { config } from "../../configs/constant";
import Table from 'react-bootstrap/Table';

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import logo from '../../asset/images/rootslot.png';

import swal from 'sweetalert';


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';


class ReportPage extends React.Component {
  state = {
    option:[],
    datetime:[],
    name:"",
    time:"",
    fullcount:0,
    data:[],
  };

  
  componentDidMount(){

    this.CallApiGetWebName();

  }






CallApiGetWebName = () =>{

  axios({
    method: 'get',
    url: (`${config.url}/content/GetWebName`),
  })
  .then(resp => {
    if(resp.data.status){

      swal("กำลังดึงข้อมูล", {
        title: "ประมวลผล",
        buttons: false,
        timer: 5000,
      });

      this.setState({
        option:resp.data.webname.map(o=> {
          return(
            <option value={o}>{o}</option>
          )
        })
      })

      this.setState({
        datetime:resp.data.dateTime.map(o=> {
          return(
            <option value={o}>{o}</option>
          )
        })
      })

    }
    else{
      swal("เกิดข้อผิดพลาด 001", "ไม่สามารถดึงข้อมูลได้ในขณะนี้",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด 002", "ไม่สามารถดึงข้อมูลได้ในขณะนี้",{ dangerMode: true});
    return
  });
}

getdata = () =>{
  this.setState({data:[]})
  axios({
    method: 'get',
    url: (`${config.url}/content/GetWebData?name=${this.state.name}&time=${this.state.time}`),
  })
  .then(resp => {

    swal("กำลังดึงข้อมูล", {
      title: "ประมวลผล",
      buttons: false,
      timer: 5000,
    });

    if(resp.data.status){

    var count_partner = 0
    this.setState({
      partner:resp.data.partnercount.map(o=> {
        count_partner += o.count+o.uncount


        this.state.data.push({
          name:o.name,
          count:o.count,
          uncount:o.uncount,
          sum:o.count+o.uncount
        })

        // return(
        //   <tr>
        //   <td>{o.name}</td>
        //   <td>{o.count}</td>
        //   <td>{o.uncount}</td>
        //   <td>{o.count+o.uncount}</td>
        // </tr>
        // )

      })
    })


  



    var count_funnel = 0
    this.setState({
      funnel:resp.data.funnelcount.map(o=> {
        count_funnel += o.count+o.uncount

        this.state.data.push({
          name:o.name,
          count:o.count,
          uncount:o.uncount,
          sum:o.count+o.uncount
        })

        // return(
        //   <tr>
        //   <td>{o.name}</td>
        //   <td>{o.count}</td>
        //   <td>{o.uncount}</td>
        //   <td>{o.count+o.uncount}</td>
        // </tr>
        // )

      })
    })

    var count_etc = 0
    this.setState({
      etc:resp.data.etccount.map(o=> {
        count_etc += o.count+o.uncount

        this.state.data.push({
          name:o.name,
          count:o.count,
          uncount:o.uncount,
          sum:o.count+o.uncount
        })

        // return(
        //   <tr>
        //   <td>{o.name}</td>
        //   <td>{o.count}</td>
        //   <td>{o.uncount}</td>
        //   <td>{o.count+o.uncount}</td>
        // </tr>
        // )

      })
    })



  this.setState({fullcount:count_partner+count_funnel+count_etc})


  this.state.data.push({
    name:"----------",
    count:"รวม",
    uncount:"----------",
    sum:count_partner+count_funnel+count_etc
  })

  console.log(this.state.data)


    }
    else{
      swal("เกิดข้อผิดพลาด 001", "ไม่สามารถดึงข้อมูลได้ในขณะนี้",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด 002", "ไม่สามารถดึงข้อมูลได้ในขณะนี้",{ dangerMode: true});
    return
  });

}


getinput = (e) =>{
  this.setState({ [e.target.name]: e.target.value });

}



  render() {


    const tableData = {
      columns : [
        {
          name: 'พาร์ทเนอร์',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'โอนสำเร็จ',
          selector: 'count',
          sortable: true,
        },
        {
          name: 'รอโอน',
          selector: 'uncount',
          sortable: true,
        },
        {
          name: 'รวม',
          selector: 'sum',
          sortable: true,
        },
      ],

      data : [
        ...this.state.data
        ]
    };
  


    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
          <br/>
          <div className='select-box'>
            <div className='select'>
                <Form.Select aria-label="Default select example" name="name" onChange={this.getinput}>
                  <option>ค่ายเกมส์</option>
                  {this.state.option}
                </Form.Select>
              </div>
              <div className='select'>
                <Form.Select aria-label="Default select example" name="time" onChange={this.getinput}>
                  <option>วันที่</option>
                  {this.state.datetime}
                </Form.Select>
            </div>
            <Button variant="primary" type="submit" onClick={this.getdata}>คัดกรอง</Button>
          </div>
          <br/>
            <div className="app-conten">
            {/* <Table striped bordered hover>
              <thead>
                <tr>
                  <th>พาร์ทเนอร์</th>
                  <th>โอนสำเร็จ</th>
                  <th>รอโอน</th>
                  <th>รวม</th>
                </tr>
              </thead>
              <tbody>

              {this.state.partner}
              {this.state.funnel}
              {this.state.etc}

              <tr>
                <td colSpan={3}>รวมทั้งหมด</td>
                <td>{this.state.fullcount}</td>
              </tr>

              </tbody>
            </Table> */}


              <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    print={false}
                  />
              </DataTableExtensions>

              <h3>รวม {this.state.fullcount}</h3>


            </div>
            

        </Container>
        </>
    );
  }
}

export default (ReportPage);