export const config = {
    //url : "https://vm-2020-api.azurewebsites.net",
    url : "https://dev-goo-api.azurewebsites.net",
    imgDef : "https://i2.wp.com/www.bengtolcollege.ac.in/wp-content/uploads/2020/07/thesomeday123170900021.jpg?w=450&ssl=1",

    liveGame :[
        {
            name:"Pretty Gaming",
            value:"pretty",
            image:"https://sv1.picz.in.th/images/2021/10/11/uf3lIt.png"
        },
        {
            name:"AE Sexy",
            value:"sexy",
            image:"https://sv1.picz.in.th/images/2021/10/11/uf3Aol.png"
        },
        {
            name:"Allbet",
            value:"allbet",
            image:"https://www.img.in.th/images/fe535c88f6c430ba4c5a6de0f5cc5892.png"
        },
        {
            name:"Sa Gaming",
            value:"sa",
            image:"https://www.img.in.th/images/f420357dca2e59dc19c02e8b41977359.png"
        },
        {
            name:"Big Gaming",
            value:"bg",
            image:"https://www.img.in.th/images/d7e13198ee1457ac03f1186bf171d4c6.png"
        },
        {
            name:"Pragmatic Play",
            value:"pragmaticplay",
            image:"https://www.img.in.th/images/e2b876eef40b27dd84548ce6e1895361.png"
        },
        {
            name:"Dream Gaming",
            value:"dreamgame",
            image:"https://www.img.in.th/images/fbac465e2eb92261d3cb7dd90ae0a183.png"
        },
        {
            name:"eBet",
            value:"ebet",
            image:"https://www.img.in.th/images/1d0f1fcc1956586cac4915394c76a1ee.png"
        },
        {
            name:"Asia Gaming",
            value:"ag",
            image:"https://www.img.in.th/images/4419d9e887381a3d1d7aef5175132fa6.png"
        },
        {
            name:"betgames.tv",
            value:"betgame",
            image:"https://www.img.in.th/images/84fc2335386f9ca84f0f14dc606f03d4.png"
        },
        {
            name:"Green Dragon",
            value:"greendragon",
            image:"https://www.img.in.th/images/180bfd40f4382d9e4c13a05ea7ccb8ec.png"
        },
    ],


    headers :{'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},

    ListBank:["-- โปรดเลือกธนาคาร --","ธนาคารแห่งประเทศไทย","ธนาคารกรุงเทพ","ธนาคารกสิกรไทย	","ธนาคารกรุงไทย","ธนาคารทหารไทย","ธนาคารไทยพาณิชย์",
    "ธนาคารกรุงศรีอยุธยา","ธนาคารเกียรตินาคิน","ธนาคารซีไอเอ็มบีไทย","ธนาคารทิสโก้","ธนาคารธนชาต","ธนาคารยูโอบี","ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร","ธนาคารออมสิน","ธนาคารอาคารสงเคราะห์","	ธนาคารอิสลามแห่งประเทศไทย"],

};