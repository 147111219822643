import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  background: rgba(256, 256, 256, 0.6);
  background-image: url('https://i.pinimg.com/736x/a1/e2/cc/a1e2cc05302a5a123a8cc75052973720.jpg');
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  color: #000000;
  z-index:900;
  .app-conten {
    width: 90%;
    padding: 10px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
    }
    .fix-img {
      max-width: 300px;
    }
    .c-h2{
      color: #ffffff;
    }
    div {
      align-items: center;
      justify-content: center;
    }
    .fix-center {
      max-width: 768px;
      margin:auto;
      text-align: center;
    }
    .fix-text-left{
      text-align: left;
    }
    .fix-loading{
      width: 7%;
    }
    .app-login-group {
      font-size: 20px;
      border-radius: 50px;
      height: 50px;
      position: relative;
      &.btn-line {
        background-color: #00b300;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-facebook {
        background-color: #4267b2;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-apple {
        background-color: #000000;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-login {
        background-color: #ba0001;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .fix-size-iconline{
        font-size: 25px;
    }
    .app-center-div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    .image-profile{
      width: 100% ;
      margin: auto;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cover-profile{
      height:250px;
      padding-top: 10px;
      margin-bottom: 15px;
      background-image: url("https://s3.envato.com/files/6768fb83-7185-4103-971b-0dbc61d8395c/inline_image_preview.jpg");
    }
    input {
      border: solid 1px #cccccc;
    }
    h4 {
      margin-top: 5px;
      font-size: 18px;
      color: #7f7f7f;
    }
    h5 {
      margin-top: 5px;
      font-size: 16px;
      color: #ffffff;
    }
    li {
      font-size: 12px !important;
    }
  }
`;
