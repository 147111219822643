import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  background: rgba(256, 256, 256, 0.6);
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  color: #000000;
  z-index:900;
  .select-box{
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .select{
      width: 40%;
      margin: 5px;
      display: inline-block;
      border: 1px solid #7f7f7f;
    }
  .print{
    display:none !important;
  }
  .app-conten {
    width: 90%;
    padding: 10px;
    margin: auto;
    img {
      width: 100%;
    }
    .fix-img {
      max-width: 300px;
    }
    div {

    }
    .rdt_TableHeadRow{
      font-size: 25px;
      font-weight: 600;
    }

    .fix-center {
      max-width: 768px;
      margin:auto;
      text-align: center;
    }
    .fix-text-left{
      text-align: left;
    }
    .fix-loading{
      width: 7%;
    }
    .app-login-group {
      font-size: 20px;
      border-radius: 50px;
      height: 50px;
      position: relative;
      &.btn-line {
        background-color: #00b300;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-facebook {
        background-color: #4267b2;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-apple {
        background-color: #000000;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-login {
        background-color: #ba0001;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .fix-size-iconline{
        font-size: 25px;
    }
    .app-center-div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    .image-profile{
      width: 100% ;
      margin: auto;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cover-profile{
      height:250px;
      padding-top: 10px;
      margin-bottom: 15px;
      background-image: url("https://s3.envato.com/files/6768fb83-7185-4103-971b-0dbc61d8395c/inline_image_preview.jpg");
    }
    input {
      border: solid 1px #cccccc;
    }
    h4 {
      margin-top: 5px;
      font-size: 18px;
      color: #7f7f7f;
    }
    h5 {
      margin-top: 5px;
      font-size: 16px;
      color: #ffffff;
    }
    li {
      font-size: 12px !important;
    }
  }
`;
